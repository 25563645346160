<template>
  <div class="container">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: "Container",
  created() {},
  setup() {},
  methods: {},
};
</script>

<style scoped>
</style>
